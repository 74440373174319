@import "/src/constants";

#storybook-section {
  background-color: $beige;
  max-width: unset;
  padding: 64px 0;

  h1 {
    color: $orange;
    margin: 0 auto 32px;
    padding: 0 32px;

    span {
      color: $dark-chocolate;
    }
  }

  #story-bg-1 {
    background-color: $dark-chocolate;

    p {
      color: $beige;
    }
  }

  #story-bg-2 {
    background-color: $dark-chocolate;

    p {
      color: $beige;
    }
  }

  .story-bg-wrapper {
    .story-slant {
      height: 128px;
    }

    #story-slant-1 {
      background: linear-gradient(to right bottom, $beige 49%, transparent 50%);
    }

    #story-slant-2 {
      background: linear-gradient(to right top, $beige 49%, transparent 50%);
    }

    #story-slant-3 {
      background: linear-gradient(to right bottom, $beige 49%, transparent 50%);
    }

    #story-slant-4 {
      background: linear-gradient(to right top, $beige 49%, transparent 50%);
    }
  }

  .story-leafs-cont {
    display: flex;
    flex-direction: column;
    gap: 128px;
    max-width: 1292px;
    margin: 0 auto;

    &:not(.story-point-cont) {
      padding: 64px 0;
    }

    .story-leaf {
      display: flex;
      gap: 128px;

      .story-leaf-side {
        display: flex;
        flex-direction: column;
        width: 50%;

        &:first-child {
          align-items: flex-end;
          text-align: right;
        }

        &.story-low-down-text {
          justify-content: flex-end;

          p {
            margin-bottom: 64px;
          }
        }

        img:not([role="presentation"]) {
          display: block;
          max-width: 360px;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
          z-index: 1;
          border-radius: $standard-border-radius;
        }

        p {
          font-family: "Permanent Marker", cursive;
          font-size: 40px;
          z-index: 2;

          &:first-child {
            margin-top: 64px;
          }
        }
      }

      #cookie-cutter-icon {
        height: 64px;
        width: 64px;
        transform: rotate(-12deg);
        margin-top: 32px;
        margin-right: 96px;
        align-self: flex-end;
      }

      #immolation-icon {
        height: 64px;
        width: 64px;
        transform: rotate(12deg);
        margin-top: 32px;
        margin-left: 96px;
        align-self: flex-start;
      }
    }

    .story-point {
      p {
        font-family: "Permanent Marker", cursive;
        font-size: 64px;
        text-align: center;
      }
    }

    #story-point-1 p span {
      color: $brown;
    }

    #story-point-2 p span {
      color: $orange;
    }
  }
}

@media (max-width: 1400px) {
  #storybook-section {
    .story-bg-wrapper .story-slant {
      height: 64px;
    }

    .story-leafs-cont {
      gap: 64px;

      &:not(.story-point-cont) {
        padding: 32px 32px 64px;
      }

      .story-leaf {
        flex-direction: column;
        align-items: center;
        gap: 0;

        .story-leaf-side {
          align-items: center !important;
          width: 100%;
          max-width: 582px;

          p {
            margin: 64px auto;
            text-align: center;
          }

          #cookie-cutter-icon,
          #immolation-icon {
            display: none;
          }
        }
      }

      #marcus-story-leaf,
      #unique-story-leaf {
        .story-leaf-side:first-of-type {
          order: 1;
        }
      }

      .story-point {
        padding: 0 64px;
      }
    }
  }
}

@media (max-width: 800px) {
  #storybook-section {
    padding: 32px 0;

    h1 {
      padding: 0 64px;
    }

    .story-bg-wrapper .story-slant {
      height: 32px;
    }

    .story-leafs-cont {
      gap: 32px;

      .story-leaf {
        .story-leaf-side {
          p {
            margin-top: 48px !important;
            margin-bottom: 48px !important;
            font-size: 32px;
          }
        }
      }

      .story-point {
        padding: 0 32px;

        p {
          margin-top: 32px;
          margin-bottom: 32px;
          font-size: 40px;
        }
      }
    }
  }
}
