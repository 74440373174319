#storybook-section {
  max-width: unset;
  background-color: #e2efb3;
  padding: 64px 0;
}

#storybook-section h1 {
  color: #ff4500;
  margin: 0 auto 32px;
  padding: 0 32px;
}

#storybook-section h1 span {
  color: #1e2019;
}

#storybook-section #story-bg-1 {
  background-color: #1e2019;
}

#storybook-section #story-bg-1 p {
  color: #e2efb3;
}

#storybook-section #story-bg-2 {
  background-color: #1e2019;
}

#storybook-section #story-bg-2 p {
  color: #e2efb3;
}

#storybook-section .story-bg-wrapper .story-slant {
  height: 128px;
}

#storybook-section .story-bg-wrapper #story-slant-1 {
  background: linear-gradient(to bottom right, #e2efb3 49%, #0000 50%);
}

#storybook-section .story-bg-wrapper #story-slant-2 {
  background: linear-gradient(to top right, #e2efb3 49%, #0000 50%);
}

#storybook-section .story-bg-wrapper #story-slant-3 {
  background: linear-gradient(to bottom right, #e2efb3 49%, #0000 50%);
}

#storybook-section .story-bg-wrapper #story-slant-4 {
  background: linear-gradient(to top right, #e2efb3 49%, #0000 50%);
}

#storybook-section .story-leafs-cont {
  max-width: 1292px;
  flex-direction: column;
  gap: 128px;
  margin: 0 auto;
  display: flex;
}

#storybook-section .story-leafs-cont:not(.story-point-cont) {
  padding: 64px 0;
}

#storybook-section .story-leafs-cont .story-leaf {
  gap: 128px;
  display: flex;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side {
  width: 50%;
  flex-direction: column;
  display: flex;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side:first-child {
  text-align: right;
  align-items: flex-end;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side.story-low-down-text {
  justify-content: flex-end;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side.story-low-down-text p {
  margin-bottom: 64px;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side img:not([role="presentation"]) {
  max-width: 360px;
  z-index: 1;
  border-radius: 4%;
  display: block;
  box-shadow: 0 10px 15px -3px #0000001a;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side p {
  z-index: 2;
  font-family: Permanent Marker, cursive;
  font-size: 40px;
}

#storybook-section .story-leafs-cont .story-leaf .story-leaf-side p:first-child {
  margin-top: 64px;
}

#storybook-section .story-leafs-cont .story-leaf #cookie-cutter-icon {
  height: 64px;
  width: 64px;
  align-self: flex-end;
  margin-top: 32px;
  margin-right: 96px;
  transform: rotate(-12deg);
}

#storybook-section .story-leafs-cont .story-leaf #immolation-icon {
  height: 64px;
  width: 64px;
  align-self: flex-start;
  margin-top: 32px;
  margin-left: 96px;
  transform: rotate(12deg);
}

#storybook-section .story-leafs-cont .story-point p {
  text-align: center;
  font-family: Permanent Marker, cursive;
  font-size: 64px;
}

#storybook-section .story-leafs-cont #story-point-1 p span {
  color: #6e260e;
}

#storybook-section .story-leafs-cont #story-point-2 p span {
  color: #ff4500;
}

@media (max-width: 1400px) {
  #storybook-section .story-bg-wrapper .story-slant {
    height: 64px;
  }

  #storybook-section .story-leafs-cont {
    gap: 64px;
  }

  #storybook-section .story-leafs-cont:not(.story-point-cont) {
    padding: 32px 32px 64px;
  }

  #storybook-section .story-leafs-cont .story-leaf {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  #storybook-section .story-leafs-cont .story-leaf .story-leaf-side {
    width: 100%;
    max-width: 582px;
    align-items: center !important;
  }

  #storybook-section .story-leafs-cont .story-leaf .story-leaf-side p {
    text-align: center;
    margin: 64px auto;
  }

  #storybook-section .story-leafs-cont .story-leaf .story-leaf-side #cookie-cutter-icon, #storybook-section .story-leafs-cont .story-leaf .story-leaf-side #immolation-icon {
    display: none;
  }

  #storybook-section .story-leafs-cont #marcus-story-leaf .story-leaf-side:first-of-type, #storybook-section .story-leafs-cont #unique-story-leaf .story-leaf-side:first-of-type {
    order: 1;
  }

  #storybook-section .story-leafs-cont .story-point {
    padding: 0 64px;
  }
}

@media (max-width: 800px) {
  #storybook-section {
    padding: 32px 0;
  }

  #storybook-section h1 {
    padding: 0 64px;
  }

  #storybook-section .story-bg-wrapper .story-slant {
    height: 32px;
  }

  #storybook-section .story-leafs-cont {
    gap: 32px;
  }

  #storybook-section .story-leafs-cont .story-leaf .story-leaf-side p {
    font-size: 32px;
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  #storybook-section .story-leafs-cont .story-point {
    padding: 0 32px;
  }

  #storybook-section .story-leafs-cont .story-point p {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 40px;
  }
}

/*# sourceMappingURL=index.dd34e314.css.map */
